import { Flash } from "./flash.js";

export const StashedFileUI = {
  locateProgressElement(clientId) {
    /* Return the delegate if it exists */
    const explicit = document.querySelector(`[data-stashed-file-client-id='${clientId}']`);
    if (explicit) {
      return explicit;
    }

    /* search out links and forms.  result is a button-like object */
    const selector = [
      `a[href*='stashed-file-client-id=${clientId}']`,
      `form[action*='stashed-file-client-id=${clientId}'] button[type=submit]`,
    ].join(",");

    return document.querySelector(selector);
  },

  /* Extracts the client_id from a link with a href or button inside a form with an action */
  extractClientId(element) {
    /* extract from parent form if this is a button */
    if (element.nodeName === "BUTTON") {
      element = element.closest("form");
    }

    /* try href and action attributes */
    return ["href", "action"]
      .map((attr) => {
        const url = element.getAttribute(attr);
        if (url) {
          const matches = url.match(/stashed-file-client-id=([a-zA-Z0-9_]+)/);
          return matches && matches[1];
        } else {
          return false;
        }
      })
      .filter((v) => v)[0];
  },

  disableButton(button) {
    if (button) {
      button.classList.add("is-downloading", "is-loading");
      button.classList.remove("is-downloading-error", "is-downloading-ready");
      button.setAttribute("disabled", true);
    }
  },

  resetButton(button, ...extra) {
    if (button) {
      button.classList.remove(
        "is-downloading",
        "is-loading",
        "is-downloading-error",
        "is-downloading-ready"
      );
      button.removeAttribute("disabled");
      if (extra.length > 0) {
        button.classList.add(...extra);
      }
    }
  },

  onStartEvent(ev) {
    this.disableButton(this.locateProgressElement(ev.clientId));
  },

  onErrorEvent(ev) {
    Flash.error("Error generating file.");
    this.resetButton(this.locateProgressElement(ev.clientId), "is-downloading-error");
  },

  onNetworkErrorEvent(ev) {
    Flash.error("A network error occurred.");
    this.resetButton(this.locateProgressElement(ev.clientId), "is-downloading-error");
  },

  onReadyEvent(ev) {
    if (ev.notification) {
      Flash.success(ev.progressMessage);
    } else {
      Flash.success("File generated.");
    }

    this.resetButton(this.locateProgressElement(ev.clientId), "is-downloading-ready");
  },

  onProgressEvent(ev) {
    const element = this.locateProgressElement(ev.clientId);
    if (!element) return;
    element.classList.toggle("is-downloading-with-message", ev.progressMessage !== null);

    element.classList.toggle("is-downloading-with-percentage", ev.progressPercentage !== null);
  },

  watch() {
    document.addEventListener("stashed-file:start", (ev) => this.onStartEvent(ev));
    document.addEventListener("stashed-file:error", (ev) => this.onErrorEvent(ev));
    document.addEventListener("stashed-file:network-error", (ev) => this.onNetworkErrorEvent(ev));
    document.addEventListener("stashed-file:ready", (ev) => this.onReadyEvent(ev));
    document.addEventListener("stashed-file:progress", (ev) => this.onProgressEvent(ev));

    /* Prevent double-submit */
    const selector = [
      `a[href*='stashed-file-client-id=']`,
      `form[action*='stashed-file-client-id='] button[type=submit]`,
    ].join(",");

    document.querySelectorAll(selector).forEach((button) => {
      button.addEventListener("click", () => {
        const clientId = this.extractClientId(button);
        const delegate = this.locateProgressElement(clientId);
        /* Setting an element to disabled will actually cancel remote forms.
         * Do it after this event has been handled. */
        setTimeout(() => {
          this.disableButton(delegate);
          if (delegate !== button) {
            /* delegated UI is probably a popup menu.  Trigger blur. */
            document.body.click();
          }
        }, 1);
      });
    });
  },
};

StashedFileUI.watch();
