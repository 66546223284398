import { mapKeys, camelCase } from "lodash-es";

import uniqid from "uniqid";
import { stashedFilePath } from "routes";

/* Get us JS-flavor attributes instead of Rails flavor */
const camelizeKeys = (obj) => mapKeys(obj, (_, k) => camelCase(k));

class StashedFileProgressEvent extends Event {
  constructor(attributes) {
    super("stashed-file:progress");
    Object.assign(this, attributes);
  }
}

class StashedFileStartEvent extends Event {
  constructor(attributes) {
    super("stashed-file:start");
    Object.assign(this, attributes);
  }
}

class StashedFileReadyEvent extends Event {
  constructor(attributes) {
    super("stashed-file:ready");
    Object.assign(this, attributes);
  }
}

class StashedFileNetworkErrorEvent extends Event {
  constructor({ exception }) {
    super("stashed-file:network-error");
    Object.assign(this, { exception });
  }
}

class StashedFileErrorEvent extends Event {
  constructor(attributes) {
    super("stashed-file:error");
    Object.assign(this, attributes);
  }
}

export const StashedFile = {
  pollRate: 1500,

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  fireEvent(ev) {
    document.dispatchEvent(ev);
  },

  async fetchInfo(url) {
    const response = await fetch(url, {
      headers: {
        Accept: "application/json",
      },
    });

    if (!(response.status >= 200 && response.status < 300)) {
      throw new Error(`bad HTTP response: ${response.status}`);
    }

    return camelizeKeys(await response.json());
  },

  initiateClientDownload(url, fileName) {
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("rel", "noopener");
    document.body.appendChild(a);
    a.click();
    a.remove();
  },

  async download({ path }) {
    let firstFetch = true;
    for (;;) {
      let response;
      try {
        response = await this.fetchInfo(path);
      } catch (error) {
        this.fireEvent(new StashedFileNetworkErrorEvent({ exception: error }));
        throw error;
      }

      if (firstFetch) {
        this.fireEvent(new StashedFileStartEvent(response));
        firstFetch = false;
      }

      if (response.status === "ready") {
        this.fireEvent(new StashedFileProgressEvent(response));

        if (response.downloadUrl !== null) {
          this.initiateClientDownload(response.downloadUrl, response.fileName);
        }

        this.fireEvent(new StashedFileReadyEvent(response));
        return response;
      } else if (response.status === "error") {
        this.fireEvent(new StashedFileErrorEvent(response));
        throw new Error(response.errorMessage);
      } else if (response.status === "processing") {
        this.fireEvent(new StashedFileProgressEvent(response));
      }

      await this.sleep(this.pollRate);
    }
  },
};

/**
 * Make a request that results in a StashedFile, and waits inline and downloads or else redirects to the waiting page.
 *
 * - url: endpoint
 * - optional:
 *    - data: request payload if needed; this function sets a default for "stashed-file-client-id" (`uniqid()`)
 *    - autoDownload: if true, waits inline and downloads, else redirects to `status_url`
 *    - method: default "POST"
 *
 * Returns:
 *  { ok: true, error?: string } - where 'error' is a user-facing error to pass to `Flash.error` etc.
 *  If ok is false and `error` is false-y, the caller is responsible for the user-facing error.
 */
export async function makeStashedFileRequest(
  url,
  { data = {}, autoDownload = false, method = "POST" } = {}
) {
  const info = `url=${url} method=${method}`;

  let response;
  try {
    response = await fetch(url, {
      method: method || "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        "stashed-file-client-id": data["stashed-file-client-id"] || uniqid(),
      }),
    });
  } catch (e) {
    console.error(`makeStashedFileRequest ${info}: network error | err=${e}`);
    return { ok: false };
  }

  let json = undefined;
  try {
    json = await response.json();
  } catch (e) {
    console.error(
      `makeStashedFileRequest ${info}: parse error | resp.status=${response.status} err=${e}`
    );
    return { ok: false };
  }

  if (!response.ok) {
    const userError = json.error_message || json.error;
    console.error(
      `makeStashedFileRequest ${info}: non-2xx status=${response.status} | resp=${JSON.stringify(
        json
      )}`
    );
    return userError ? { ok: false, error: userError } : { ok: false };
  }

  const token = json.token;
  if (!token) {
    console.error(`makeStashedFileRequest ${info}: no token | resp=${JSON.stringify(json)}`);
    return { ok: false };
  }

  if (autoDownload) {
    try {
      await window.StashedFile.download({ path: stashedFilePath(token) });
      return { ok: true };
    } catch (e) {
      console.error(`makeStashedFileRequest ${info}: autoDownload error | err=${e}`);
      return { ok: false };
    }
  } else {
    const statusUrl = json.status_url;
    if (!statusUrl) {
      console.error(
        `makeStashedFileRequest ${info}: autoDownload=false but no .status_url | resp=${JSON.stringify(
          json
        )}`
      );
      return { ok: false };
    }

    window.location.assign(statusUrl);
    return { ok: true };
  }
}
